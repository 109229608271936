import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Header from '../components/header'

const questions = [
	{
		q: 'What can you engrave?',
		a: () => (
			<p className='black-90 f5 lh-copy'>We specialize in the kind of drinkware you'd purchase from brands like Yeti, Rtic, S'well, etc., but can engrave almost anything. Get in touch with details about your cup and we'll let you know straight away if we can work with it.</p>
		)
	},
	{
		q: 'Can you engrave both sides?',
		a: () => (
			<p className='black-90 f5 lh-copy'>We can! One thing to remember is that most cup brands have their own logo on one side. Our standard approach is to engrave the opposite side without any branding, but in the event you want both sides engraved, one of your designs will be positioned above the cup logo.</p>
		)
	},
	{
		q: 'Can you engrave stainless steel?',
		a: () => (
			<p className='black-90 f5 lh-copy'>We can! The resulting engraving is black and looks very sharp.</p>
		)
	},
	{
		q: 'Can I send you an art file with my logo to be engraved?',
		a: () => (
			<div>
				<p className='black-90 f5 lh-copy'>Absolutely. We can work with two kinds of art files.</p>
				<p className='black-90 f5 lh-copy'>Our preferred art file is in vector format. These are normally files with an .EPS, .AI, or .PDF extension. Your marketing team should have a version of your logo in this format, or your graphic designer should too.</p>
				<p className='black-90 f5 lh-copy'>We can also work with normal image files like .JPG or .PNG, but only if they are high-resolution &mdash; 300 to 400 DPI. Small, low-resolution files are an absolute last resort, because they almost always produce a low-quality engraving.</p>
			</div>
		)
	}
]

const FaqPage = () => (
  <Layout>
  	<div className=''>
  		<div className='w-100 w-80-ns center'>
  			<Header />
		  	<div className='mt4-ns pv4 mh4 mh0-ns'>
		  		<div>
		  			<h2 className='roboto f3 fw4 near-black'>You have questions. We have answers.</h2>
		  		</div>
		  	</div>
		  	<div className='mh4 mh0-ns'>
		  		<ul className='list ma0 pa0'>
		  			{
		  				questions.map((question, idx) =>
		  					<div className='mb5 mb4-ns' key={ idx } >
		  						<p className='roboto f3 near-black'>{ question.q }</p>
		  						{ question.a() }
		  					</div>
		  				)
		  			}
		  		</ul>
		  	</div>
  		</div>
	</div>
  </Layout>
)

export default FaqPage
